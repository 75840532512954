import React, { useEffect } from 'react';
import useAccount from '@/hooks/useAccount';
import Language from '@/utils/Language';
import { renderHelpScout } from '@/utils/utils';

const Botlist = ["Googlebot", "Google-InspectionTool", "Pingdom", "Crawler", "YandexBot", "EasouSpider", "MJ12bot", "XoviBot", "Slurp", "bingbot", "Baiduspider", "Facebook", "Yandex", "Twitter"];

// add 'staging' or 'development' to the array to see the chat box in the respective environment
const activeEnvironments = ['production'];

const Beacon = () => {
  const { account } = useAccount();

  const checkProStatus = () => {
    return account?.data?.is_professional || false
  }

  // Do not display on Family Resource Finder
  const isFRF = () => {
    if (window.location.pathname.includes('familyresourcefinder')) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if(activeEnvironments.indexOf(process.env.NODE_ENV) < 0) return;
    if (!navigator.userAgent.toLowerCase().match(new RegExp(Botlist.join('|').toLowerCase())) && !isFRF()) {
      window.Beacon ||
      (
        // Reference to the following script: https://github.com/1deg/application-server/issues/9758#issuecomment-1832593572
        !function(e,t,n){function a(){var e=t.getElementsByTagName("script")[0],n=t.createElement("script");n.type="text/javascript",n.async=!0,n.src="https://beacon-v2.helpscout.net",e.parentNode.insertBefore(n,e)}if(e.Beacon=n=function(t,n,a){e.Beacon.readyQueue.push({method:t,options:n,data:a})},n.readyQueue=[],"complete"===t.readyState)return a();e.attachEvent?e.attachEvent("onload",a):e.addEventListener("load",a,!1)}(window,document,window.Beacon||function(){})
      );

      const isPro = checkProStatus()
      
      if (isPro) {
        renderHelpScout('pro');
      } else {
        renderHelpScout(Language.getCurrentLanguage());
      }
    }
  }, [account?.data?.is_professional]);

  return <></>;
}

export default Beacon;
